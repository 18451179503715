import React, { useEffect, useState, useContext, useRef } from "react";
import { List, ListItem, ListItemAvatar, Avatar, Typography, IconButton, Tooltip, LinearProgress, Box, ButtonGroup, Button } from "@mui/material";
import GridOnIcon from '@mui/icons-material/GridOn';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ConversationsContext from '../../../context/ConversationsContext';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { utils, read } from "xlsx";
import { MuiFileInput } from 'mui-file-input';
import { toA1, columnToLetter } from '../../helpers/utility'

export const ExcelPicker = ({ setSheetInformation }) => {
    const [worksheetData, setWorksheetData] = useState({});

    const {
        file,
        setFile,
    } = useContext(ConversationsContext);


    const [loading, setLoading] = useState(false)
    const [workbookObject, setWorkbookObject] = useState({})

    const handleChange = (newFile) => {
        // console.log(newFile)
        setFile(newFile)
    }

    function handleFileSelect(event) {
        // const file = event.target.files[0];
        // console.log(event)

        const reader = new FileReader();
        // console.log(file)
        reader.onload = function (event) {
            const data = new Uint8Array(event.target.result);
            const workbook = read(data, { type: "array" });

            for (let [sheetName, sheet] of Object.entries(workbook.Sheets)) {
                workbook.Sheets[sheetName].arrayOfArrays = utils.sheet_to_json(sheet, { header: 1, defval: "" })
            }
            setWorkbookObject({ ...workbook })

            const worksheetData = {};

            workbook.SheetNames.forEach(function (sheetName) {
                const worksheet = workbook.Sheets[sheetName];
                const range = utils.decode_range(worksheet["!ref"]);
                const usedRange = {
                    start: { row: range.s.r + 1, column: range.s.c + 1 },
                    end: { row: range.e.r + 1, column: range.e.c + 1 },
                };
                const data = utils.sheet_to_row_object_array(worksheet, {
                    header: 1,
                });
                const firstDataRow = usedRange.start.row;
                const lastDataRow = data.length
                    ? findLastNonEmptyRow(data) + firstDataRow
                    : 1;
                const columnNames = data.length ? data[0].map(cname => cname.toString().replace(/ /g, "_")) : [];

                worksheetData[sheetName] = {
                    columnStart: usedRange.start.column,
                    columnEnd: usedRange.end.column,
                    originalRowStart: firstDataRow,
                    rowStart: firstDataRow,
                    rowEnd: lastDataRow,
                    columnNames: columnNames,
                    aoa: worksheet.arrayOfArrays,
                    sheetName: sheetName,
                    usedRange: usedRange,
                    textInfo() {
                        return `Worksheet Name: '${this.sheetName}',
Data Boundaries: ${toA1(this.rowStart, this.usedRange.start.column, this.rowEnd, this.usedRange.end.column)}
Column Names are located in ${toA1(this.rowStart, this.usedRange.start.column, this.rowStart, this.usedRange.end.column)}: ${this.columnNames.map((cname, i) => `"${cname || 'Undefined'}" in ${columnToLetter(i + this.usedRange.start.column)}`).join(", ")}
`}
                    //Starts on row ${firstDataRow}, column ${usedRange.start.column}. Ends on row ${lastDataRow}, column ${usedRange.end.column}.
                    //Column Names Are Located in Row ${firstDataRow}:
                };
            });

            setWorksheetData(worksheetData);

        };

        reader.onloadstart = () => { setLoading(true) }
        reader.onloadend = () => { setLoading(false) }
        reader.readAsArrayBuffer(file);
    }


    function findLastNonEmptyRow(arrayOfArrays) {
        let lastNonEmptyRow = null;
        for (let i = arrayOfArrays.length - 1; i >= 0; i--) {
            let row = arrayOfArrays[i];
            if (row.some((cell) => cell !== null && cell !== undefined && cell !== "")) {
                lastNonEmptyRow = i;
                break;
            }
        }
        return lastNonEmptyRow;
    }

    function hideSheet(sheetName) {
        setWorksheetData(worksheetData => {
            const newSheetData = { ...worksheetData }
            delete newSheetData[sheetName]
            return newSheetData
        })
    }

    // useEffect(() => {
    //     if (!workbookObject?.Sheets) return
    //     // for (let [sheetName, sheet] of Object.entries(workbookObject?.Sheets)) {
    //     //     console.log(sheetName)
    //     //     console.log(
    //     //         utils.sheet_to_json(sheet, { header: 1, defval: "" })

    //     //     )
    //     // }
    //     // console.log(workbookObject)
    // }, [workbookObject])

    function changeFirstRowNumber(sheetData, incrementer) {
        // console.log(sheetData)
        const oldRow = sheetData.rowStart
        let newRow = oldRow + incrementer
        if (newRow < sheetData.originalRowStart) newRow = sheetData.originalRowStart
        if (newRow > sheetData.rowEnd) newRow = sheetData.rowEnd

        const newColumns = sheetData.aoa[newRow - sheetData.originalRowStart]
        const sheetName = sheetData.sheetName

        setWorksheetData(worksheetData => {
            const newSheetData = { ...worksheetData }
            newSheetData[sheetName].rowStart = newRow
            newSheetData[sheetName].columnNames = newColumns.map(cname => cname.toString().replace(/ /g, "_"))
            return newSheetData
        })
    }

    const timer = useRef(null)

    const incrementContinuous = (sheetData, incrementer) => {
        timer.current = setInterval(() => changeFirstRowNumber(sheetData, incrementer), 100)
    }

    function stopIncrementContinuous() {
        clearInterval(timer.current)
    }

    function renderWorksheetData() {
        const worksheetListItems = Object.entries(worksheetData).map(
            ([sheetName, sheetData]) => (
                // <ListItem key={sheetName}>
                //   <strong>{sheetName}:</strong> Used range: {sheetData.usedRange}. Data
                //   range: {sheetData.dataRange}. Columns: {sheetData.columnNames.join(", ")}
                // </ListItem>
                <ListItem key={sheetName} alignItems="flex-start" sx={{ borderBottom: "1px dotted grey" }}>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "#729337" }}>
                            <GridOnIcon />
                        </Avatar>
                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                    </ListItemAvatar>
                    <Box style={{ flex: "1" }}>
                        <div style={{ fontWeight: "bold", lineHeight: "1.5", fontSize: "1rem" }}>{sheetName}</div>
                        <Box>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Worksheet Name: '{sheetName}'
                            </Typography>
                            <Box style={{ fontSize: "0.875rem", color: "rgba(0, 0, 0, 0.6)", lineHeight: "1.43" }}>
                                <Box style={{ display: "flex", lineHeight: "1.5rem", alignItems: "center",flexWrap: "wrap" }}>
                                    <i style={{ marginRight: "0.3rem" }}>Data Boundaries:</i> Starts on row <span className="rowCount">{sheetData.rowStart}</span>, column <span className="rowCount">{sheetData.columnStart}</span>. Ends on row <span className="rowCount">{sheetData.rowEnd}</span>, column <span className="rowCount">{sheetData.columnEnd}</span>.
                                </Box>
                                <Box style={{ display: "flex", lineHeight:"1.7",alignItems:"center",flexWrap: "wrap" }}>
                                    <i style={{ marginRight: "0.3rem" }}>Column Names:</i> {sheetData.columnNames.map((cname, i) => <span key={i} className="columnName">{cname}</span>)}
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center",marginTop: "0.5rem" }}>
                                    <span style={{ marginRight: "0.5rem",fontStyle: "italic" }}>Change header row:</span>
                                    <ButtonGroup className="btnGroupIncrementer" size="small" color="success" variant="outlined" aria-label="outlined button group">
                                        <Button
                                            onClick={() => changeFirstRowNumber(sheetData, 1)}
                                            onMouseLeave={stopIncrementContinuous}
                                            onMouseUp={stopIncrementContinuous}
                                            onMouseDown={() => incrementContinuous(sheetData, 1)}
                                        ><AddIcon /></Button>
                                        <Button
                                            onClick={() => changeFirstRowNumber(sheetData, -1)}
                                            onMouseLeave={stopIncrementContinuous}
                                            onMouseUp={stopIncrementContinuous}
                                            onMouseDown={() => incrementContinuous(sheetData, -1)}
                                        ><RemoveIcon /></Button>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Tooltip title="Add This to Your Question">
                        <IconButton aria-label="plus" onClick={() => setSheetInformation(worksheetData[sheetName].textInfo())}>
                            <AddCircleOutlineIcon style={{ color: "#ff7800", fontSize: "2.5rem" }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Hide This Sheet Information">
                        <IconButton aria-label="hide" onClick={() => hideSheet(sheetName)}>
                            <HideImageOutlinedIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                    </Tooltip>
                </ListItem>
            )
        );

        return <List>{worksheetListItems}</List>;
    }

    useEffect(() => {
        if (file == null) {
            setWorksheetData({})
            setSheetInformation("")
            return
        }
        handleFileSelect(null)
    }, [file])

    return (
        <div>
            {/* <input type="file" id="file-input" onChange={handleFileSelect} /> */}
            <div>
                <MuiFileInput
                    value={file}
                    onChange={handleChange}
                    inputProps={{
                        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }}
                    placeholder="Insert an Excel file"
                    style={{ cursor: "pointer" }} />
                {loading && <Box sx={{ width: '100%', minHeight: "56px" }}><LinearProgress /> </Box>}
            </div>
            <div>
                {renderWorksheetData()}
            </div>
        </div>
    );
}