import React, {useContext} from 'react';
import { Box } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ConversationsContext from '../../../context/ConversationsContext';

export const UserFormulasBox = () => {

    const {
        formulas,
        showCopied,
        setShowCopied,
    } = useContext(ConversationsContext);   

    const copySpanText = (event) => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(event.target);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        setShowCopied(true)
        setTimeout(() => { setShowCopied(false) }, 4000)
    }

    return (
        formulas.length > 0 && <Box p={1} sx={{ width: "100%" }}>
            <small>
                Click on the Box with a Formula to copy.
                {showCopied && <span style={{ backgroundColor: "rgb(237, 247, 237)", color: "rgb(30, 70, 32)", display: "inline-flex", padding: "0.1rem 0.4rem 0rem", borderRadius: "0.2rem", alignItems: "center", marginLeft: "0.3rem" }}><CheckCircleOutlineIcon sx={{ fontSize: "0.8rem", marginRight: "0.1rem", marginBottom: "0.1rem" }} /> Copied!</span>}
            </small>
            <div>
                {formulas.filter(f => f.formula.toString().length > 1).map((formula, i) => {
                    return <span key={i} style={{ display: "inline-block", cursor: "pointer" }} onClick={copySpanText}>
                        <pre
                            className="preFormula">
                            ={formula.formula}
                        </pre>
                        <span className="not-comatible-formulas-wrapper">
                            {
                                formula && formula.noncompatible && formula.noncompatible.filter(v => v.versions.length > 0).map((func, i) => {
                                    return <span className="not-comatible-formula" key={i}><span><ErrorOutlineIcon sx={{ fontSize: "0.8rem" }} /> Function <b>{func.funcname}</b> is not compatible with versions: </span><span className="not-comatible-formula-versions">{func.versions.map((version, i) => <span key={i} className="not-comatible-formula-version-label">{version}</span>)}</span></span>
                                })
                            }
                        </span>
                    </span>
                })}
            </div>
        </Box>
    );
}