// ConversationsList.js
import React, { useContext } from 'react';
import { List, ListItemButton, ListItemText, ListItemSecondaryAction, IconButton, Button, Box } from "@mui/material";
import Delete from '@mui/icons-material/Delete';
import ConversationsContext from '../../../context/ConversationsContext';
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

const ConversationsList = () => {
    const {
        conversations,
        addConversation,
        removeConversation,
        agreed,
        agreedTerms
    } = useContext(ConversationsContext);

    const { conversationId } = useParams();
    const navigate = useNavigate()

    return (
        <div style={{ width: "100%" }}>

            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "1rem" }}>
                {agreed && agreedTerms && <Button style={{ cursor: "hand", backgroundColor: "black", color: "white" }} size="small" variant="outlined" startIcon={<ControlPointDuplicateIcon />} color="secondary" onClick={() => addConversation(navigate)}>
                    Create Q&A
                </Button>}
            </Box>

            <List>
                {Object.keys(conversations).map(id => (

                    <ListItemButton component={Link} to={`conversations/${id}`} key={id}
                        style={conversationId === id ? { backgroundColor: "#eef4e2" } : {}}>
                        <ListItemText primary={conversations[id].title} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={(e) => removeConversation(id, e, conversationId, navigate)}>
                                <Delete />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItemButton>

                )).reverse()}
            </List>
        </div>
    );
};

export default ConversationsList;
