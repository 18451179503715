import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import BugReportIcon from '@mui/icons-material/BugReport';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { FireactContext } from "../Fireact";

export const MainMenu = ({customItems}) => {
    const { config } = useContext(FireactContext);
    const pathnames = config.pathnames;
    const profileUrl = pathnames.UserProfile;
    return (
        <List component="nav">
            <NavLink to="/" style={{textDecoration:'none'}} key="home">
                <ListItemButton>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary={<Typography color="textPrimary">Home</Typography>} />
                </ListItemButton>
            </NavLink>
            <Divider key="profile-divider"/>
            <NavLink to="/privacy" style={{textDecoration:'none'}} key="privacy">
                <ListItemButton>
                    <ListItemIcon><PrivacyTipIcon /></ListItemIcon>
                    <ListItemText primary={<Typography color="textPrimary">Privacy</Typography>} />
                </ListItemButton>
            </NavLink>
            <NavLink to="/terms" style={{textDecoration:'none'}} key="terms">
                <ListItemButton>
                    <ListItemIcon><AdminPanelSettingsIcon /></ListItemIcon>
                    <ListItemText primary={<Typography color="textPrimary">Terms of Use</Typography>} />
                </ListItemButton>
            </NavLink>
            {customItems}
            {profileUrl && [
                <NavLink to={profileUrl} style={{textDecoration:'none'}} key="profile">
                    <ListItemButton>
                        <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">My Profile</Typography>} />
                    </ListItemButton>
                </NavLink>
            ]}
            <Divider key="bug-divider"/>
            <NavLink target="_blank" to="https://github.com/chicagocomputerclasses/sheetsexcellence/issues" style={{textDecoration:'none'}} key="sheetsexcellence">
                <ListItemButton>
                    <ListItemIcon><BugReportIcon /></ListItemIcon>
                    <ListItemText primary={<Typography color="textPrimary">Report Bugs</Typography>} />
                </ListItemButton>
            </NavLink>
        </List>
    )
}