// context/ConversationsProvider.js
import React, { useState, useEffect, useRef } from 'react';
import ConversationsContext from './ConversationsContext';
import { v4 as uuidv4 } from 'uuid';


const ConversationsProvider = ({ children }) => {
    // Your state and logic here

    const [sendVerification, setSendVerification] = useState({
        'success': false,
        'error': null
    })

    const [file, setFile] = useState(null)

    const [agreed, setAgreed] = useState(localStorage.getItem('privacyPolicyAgreed') === 'true');
    const [agreedTerms, setAgreedTerms] = useState(localStorage.getItem('termsAgreed') === 'true');

    const [sameSheetChecked, setSameSheetChecked] = useState(true);

    const [question, setQuestion] = useState("")

    const [formulas, setFormulas] = useState(() => {
        const storedData = localStorage.getItem("formulas")
        return storedData ? JSON.parse(storedData) : []
    })
    const [messagesData, setMessagesData] = useState(() => {
        const storedData = localStorage.getItem("conversation")
        return storedData ? JSON.parse(storedData) : []
    })

    const [loadingAnswer, setLoadingAnswer] = useState(false)
    const [showCopied, setShowCopied] = useState(false)
    const [sheetInformation, setSheetInformation] = useState("")

    const excelFunctions = useRef({});

    useEffect(() => {
        localStorage.setItem("formulas", JSON.stringify(formulas))
    }, [formulas])

    // conversatons & messages module

    const [conversations, setConversations] = useState(() => {
        const storedConversations = JSON.parse(localStorage.getItem('conversations'));
        return storedConversations ?? [];
    });

    const [newMessage, setNewMessage] = useState('');

    // sync conversations with localStorage
    useEffect(() => {
        localStorage.setItem('conversations', JSON.stringify(conversations));
    }, [conversations]);

    function addConversation(navigate) {
        let highestConversationNumber = 0;
        for (const key in conversations) {
            const title = conversations[key].title;
            const numberString = title.match(/\d+$/); // Extract the number at the end of the title
            if (numberString) {
                const number = parseInt(numberString, 10); // Convert the number string to an integer
                if (number > highestConversationNumber) {
                    highestConversationNumber = number;
                }
            }
        }
        // const highestConversationNumber = 5
        const newConversationNumber = highestConversationNumber + 1;
        const id = uuidv4();
        const newConversation = { title: `Q&A ${newConversationNumber}`, messages: [], active: true };

        const updatedConversations = {...conversations, [id]: newConversation};
        setConversations(updatedConversations);
        // setSelectedConversation(newConversation); // Set the new conversation as selected
        // localStorage.setItem('conversations', JSON.stringify(updatedConversations));
        localStorage.setItem('selectedConversationId', id); // Set the ID of the new conversation as the selected conversation ID in localStorage
        navigate(`conversations/${id}`)
    }



    function removeConversation(id, e, conversationId,navigate) {
        e.stopPropagation()
        e.preventDefault();
        // console.log("removeConversation")
        // console.log(id)
        const updatedConversations = {...conversations}
        delete updatedConversations[id]
        console.log(updatedConversations)
        // localStorage.removeItem(`messages-${id}`);

        // navigate("/")
        if (conversationId && conversationId === id && Object.keys(updatedConversations).length > 0) {
            const allIds = Object.keys(updatedConversations)
            const lastId =  allIds[allIds.length-1]
            navigate(`conversations/${lastId}`)
        } else {
            // setSelectedConversation(null)
            navigate(`conversations/`)
            localStorage.removeItem('selectedConversationId')
        }
        setConversations(updatedConversations);
        // localStorage.setItem('conversations', JSON.stringify(updatedConversations));

    }

    function handleNewMessageChange(event) {
        setNewMessage(event.target.value);
    }

    function handleNewMessageSubmit(event,conversationId) {
        event.preventDefault();
        // console.log(event)
        // console.log(conversationId)
        const updatedConversations = {...conversations}
        const newMessages = [...updatedConversations[conversationId].messages, { id: uuidv4(), content: newMessage, role: "user" }];
        updatedConversations[conversationId].messages = newMessages
        setConversations(updatedConversations)
        setNewMessage('');
        // localStorage.setItem('conversations', JSON.stringify(updatedConversations));
        // localStorage.setItem(`messages-${selectedConversation.id}`, JSON.stringify(newMessages));
    }


    return (
        <ConversationsContext.Provider
            value={{
                conversations,
                setConversations,
                newMessage,
                setNewMessage,
                addConversation,
                removeConversation,
                handleNewMessageChange,
                handleNewMessageSubmit,
                question,
                setQuestion,
                formulas,
                setFormulas,
                messagesData,
                setMessagesData,
                loadingAnswer,
                setLoadingAnswer,
                showCopied,
                setShowCopied,
                sheetInformation,
                setSheetInformation,
                excelFunctions,
                sendVerification,
                setSendVerification,
                file,
                setFile,
                sameSheetChecked,
                setSameSheetChecked,
                agreed,
                setAgreed,
                agreedTerms,
                setAgreedTerms
            }}
        >
            {children}
        </ConversationsContext.Provider>
    );
};

export default ConversationsProvider;