import { Alert, Grid, Box,  Paper, Container} from "@mui/material";
import React, { useContext, useEffect} from "react";
import { AuthContext } from "../Auth";
import { Link } from "react-router-dom";

import { SetPageTitle } from "../SetPageTitle";

import ConversationsList from "../main/ConversationsList";

import ConversationsContext from '../../../context/ConversationsContext';

export const UserHome = () => {
    // const navigate = useNavigate();

    const {
        messagesData,
        sendVerification,
        agreed,
        agreedTerms
    } = useContext(ConversationsContext);

    useEffect(() => {
        localStorage.setItem("conversation", JSON.stringify(messagesData))
    }, [messagesData])

    return (
        <AuthContext.Consumer>
            {context => (
                <Container maxWidth="md">
                    <SetPageTitle title="Speadsheet AI" />
                    <Paper>
                        {sendVerification.error !== null &&
                            <Box p={2}>
                                <Alert severity="error">{sendVerification.error}</Alert>
                            </Box>
                        }
                        {sendVerification.success &&
                            <Box p={2}>
                                <Alert severity="success">Please check your email inbox to verify the email address. Refresh this page after you verified your email address.</Alert>
                            </Box>
                        }

                        <Grid container spacing={1}>
                            <Grid container item xs={12} md={12}>
                                { agreed && agreedTerms  &&  <ConversationsList /> }
                                { ( !agreed )  &&  <Grid item xs={12} sx={{padding:"1rem"}}><Alert severity="warning">Pleasse review and agree to our <Link to="/privacy">Privacy Policy</Link></Alert></Grid> }
                                { ( !agreedTerms )  &&  <Grid item xs={12} sx={{padding:"1rem"}}><Alert severity="warning">Pleasse review and accept our <Link to="/terms">Terms of Use</Link></Alert></Grid> }
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            )}
        </AuthContext.Consumer>
    )
}