import React, { useMemo } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Box } from "@mui/material";
import { removeFormulaTags } from "../../helpers/utility";

export const UserMessageList = ({ messagesData }) => {
  const memoizedList = useMemo(() => {
    const newMessageList = [...messagesData];
    return newMessageList.map((message, i) => {
      return (
        <ListItem key={message.key} alignItems="flex-start" className={message.role} style={{ whiteSpace: "pre-wrap", borderBottom: "1px solid lightgrey", wordWrap: "break-word", maxWidth: "744px" }}>
          <ListItemAvatar>
            <Avatar alt="Assistant" src={message.role === "user" ? "" : "/avatar.png"} />
          </ListItemAvatar>
          <ListItemText
            primary={message.role === "user" ? "Question" : "Answer"}
            secondary={
              <React.Fragment>
                {removeFormulaTags(message.content)}
              </React.Fragment>
            }
          />
        </ListItem>
      );
    }).reverse();
  }, [messagesData]);

  return (
    <Box p={1}>
      <List>
        {memoizedList}
      </List>
    </Box>
  );
};