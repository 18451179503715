import React, { useContext, useEffect, useRef } from "react";
import { TextField, Button, CircularProgress, TextareaAutosize, Box, FormControlLabel, FormGroup, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Switch from '@mui/material/Switch';

import { extractFormulasInTags, getSpreadsheetFunctionNames, removeExtraProperties, updateConversations } from "../../helpers/utility"

import { collection, getDocs } from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { FireactContext } from "../Fireact";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ExcelPicker } from "../excelPicker/excelPicker"

import ConversationsContext from '../../../context/ConversationsContext';
import { v4 as uuidv4 } from 'uuid';

export const UserQuestionFormBox = ({ conversationId }) => {

    const auth = getAuth();

    // const excelFunctions = useRef({});
    const questionInputRef = useRef(null);

    const {
        question,
        setQuestion,
        setFormulas,
        conversations,
        setConversations,
        loadingAnswer,
        setLoadingAnswer,
        sheetInformation,
        setSheetInformation,
        excelFunctions,
        sameSheetChecked,
        setSameSheetChecked
    } = useContext(ConversationsContext);

    const { config } = useContext(FireactContext)
    // const pathnames = config.pathnames

    // Initialize Firebase
    const app = initializeApp(config.firebaseConfig)
    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(app)
    // const currentUser = auth.currentUser
    const functions = getFunctions()
    const askAssistant = httpsCallable(functions, 'askAssistant')


    const callAssistant = async (newData, conversationId, event) => {
        return new Promise((resolve, reject) => {
            try {
                // setMessagesData((prevData) => [...prevData, { "role": newData.role, "content": newData.content, "key": new Date().getTime() }]);

                // const updatedConversations = {...conversations}
                // const newMessages = [...updatedConversations[conversationId].messages, { "role": newData.role, "content": newData.content, "key": uuidv4() }];
                // updatedConversations[conversationId].messages = newMessages

                const { updatedConversations, newMessages } = updateConversations(
                    { "role": newData.role, "content": newData.content, "key": uuidv4() },
                    conversations,
                    conversationId
                )
                setConversations(updatedConversations)

                const copyNewMessages =  JSON.parse(JSON.stringify(newMessages))
                copyNewMessages[copyNewMessages.length-1].content = copyNewMessages[copyNewMessages.length-1].content + (sameSheetChecked === true ? ` . My formula is on the same sheet, so please don't include worksheet references.` : ` . My formula is on a different sheet, please include worksheet references.`)

                askAssistant({ messages: removeExtraProperties(copyNewMessages).slice(-4) })
                    .then((data) => {
                        // Read result of the Cloud Function.
                        /** @type {any} */
                        // const data = result.data;
                        // const sanitizedMessage = data.text;
                        // console.log("Assisting..")
                        // console.log(data)
                        let answerText
                        if (typeof data === "undefined" || typeof data.data === "undefined" || typeof data.data.answer === "undefined" || data.data.answer === "") {
                            answerText = "I'm unable to answer any questions at the moment. Please try in a few minutes."
                            console.log("No valid answer returned")
                        } else {
                            answerText = data.data.answer
                        }

                        // setMessagesData((prevData) => [...prevData, { "role": "assistant", "content": data.data.answer, "key": uuidv4() }]);
                        const { updatedConversations, } = updateConversations(
                            { "role": "assistant", "content": answerText, "key": uuidv4() },
                            conversations,
                            conversationId
                        )
                        setConversations(updatedConversations)

                       
                        setFormulas(() => extractFormulasInTags(data.data.answer).map(f => {
                            return { formula: f, functions: getSpreadsheetFunctionNames(f), noncompatible: getSpreadsheetFunctionNames(f).map(funcname => { return { funcname: funcname, versions: excelFunctions.current?.data?.[funcname]?.["noncompatible"] } }) }
                        }))
                        // console.log(excelFunctions.current?.data?.["XLOOKUP"]?.["noncompatible"])
                        resolve()
                    })
                    .catch((error) => {
                        // Getting the Error details.
                        // const code = error.code;
                        // const message = error.message;
                        // const details = error.details;
                        // ...
                        reject(error)
                        console.log(error)
                    });


            } catch (error) {
                console.log(error)
                switch (error.code) {
                    case "auth/requires-recent-login":
                        // setError("This operation is sensitive and requires recent authentication. Log in again before retrying this request.");
                        break;
                    default:
                        // setError(error.message);
                        break;
                }
                reject(error)
                // setProcessing(false);
            }
        })
    };

    const handleSubmit = async (event, conversationId) => {
        event.preventDefault();
        setLoadingAnswer(true)
        // const data = new FormData(event.target);
        const newData = {
            role: "user",
            content: question,
            key: uuidv4()
        };
        // setMessagesData((prevData) => [...prevData, newData]);

        try {
            await callAssistant(newData, conversationId, event)
            setLoadingAnswer(false)
            setQuestion("")
        } catch (e) {
            console.log("Something is wrong. didn't get an answer.")
            setLoadingAnswer(false)
        }


    };

    const handleSameSheetChecked = (event) => {
        setSameSheetChecked(event.target.checked);
    };


    useEffect(() => {
        if (sheetInformation === "" || typeof sheetInformation === "undefined" || sheetInformation === null) return
        questionInputRef.current.focus()
        setQuestion(oldQuestion => oldQuestion + "\n" + sheetInformation)
        questionInputRef.current.focus()
        setSheetInformation("")
    }, [sheetInformation])


    useEffect(() => {
        const fetchExcelFunctions = async () => {
            // console.log("Fetching excelFunctions Data from Firestore")
            const querySnapshot = await getDocs(collection(db, "excelFunctions"));
            const documents = {}
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                documents[doc.id] = doc.data()
            });
            const objectToStore = {
                data: documents,
                storedAt: new Date()
            }
            excelFunctions.current = objectToStore;
            localStorage.setItem('excelFunctions', JSON.stringify(objectToStore));
        };

        const storedExcelFunctions = localStorage.getItem('excelFunctions');
        if (storedExcelFunctions) {
            const parsedDocuments = JSON.parse(storedExcelFunctions);
            const storedDate = new Date(parsedDocuments.storedAt);
            const currentDate = new Date();
            const diffInMonths = (currentDate.getFullYear() - storedDate.getFullYear()) * 12 + currentDate.getMonth() - storedDate.getMonth();

            if (diffInMonths < 1) {
                excelFunctions.current = parsedDocuments;
                return;
            }
        }

        fetchExcelFunctions();
    }, [])

    return (
        <>
            <Box p={1} style={{width:"100%"}}>
                <ExcelPicker setSheetInformation={setSheetInformation} />
            </Box>
            <Box p={1} sx={{ width: "100%", border: '1px dotted lightgrey' }}>
                <h3 style={{ marginBottom: "0"}}>Ask a Question</h3>
                <Typography variant="caption" display="block" gutterBottom>
                    Use common sense! The answer could be convincing, but inaccurate.
                </Typography>
                {loadingAnswer ? (
                    <CircularProgress />
                ) : (

                    <form onSubmit={(e) => handleSubmit(e, conversationId)}>
                        <div style={{ marginBottom: "1rem" }}>
                            <TextField
                                fullWidth
                                multiline
                                label="Type your question here.."
                                InputProps={{
                                    inputComponent: TextareaAutosize,
                                    rows: 3
                                }}
                                value={question}
                                onChange={(event) => { setQuestion(event.target.value) }}
                                inputRef={questionInputRef}
                            />
                        </div>
                        <div>
                            <FormGroup style={{ marginBottom: "1rem" }}>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Switch color="warning" checked={sameSheetChecked}
                                            onChange={handleSameSheetChecked}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Formula is On the Same Sheet as Data"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                            <Button style={{ backgroundColor: "#ff7800" }} variant="contained" type="submit" endIcon={<SendIcon />}>
                                Send
                            </Button>
                        </div>
                    </form>
                )}
            </Box>
        </>
    );
};