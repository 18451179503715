export const extractFormulas = (text) => {
  const regex = /=\w+\([^\)]+\)/g;
  const matches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    matches.push(match[0]);
  }

  return matches;
} // end extractFormulas


export const removeDuplicates = (arr) => {
  const uniqueArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (!uniqueArr.includes(arr[i])) {
      uniqueArr.push(arr[i]);
    }
  }
  return uniqueArr;
}

export const extractFormulasInTags = (str) => {
  const regex = /<formula>\s*?=(.*?)<\/formula>/g;
  const formulas = [];
  let match;
  while (match = regex.exec(str)) {
    formulas.push(match[1].toString().trim());
  }
  const regexCode = /<code>\s*?=(.*?)<\/code>/g;
  let codeMatch;
  while (codeMatch = regexCode.exec(str)) {
    formulas.push(codeMatch[1].toString().trim());
  }
  return removeDuplicates(formulas);
} // end extractFormulasInTags

export const removeFormulaTags = (str) => {
  return str.replace(/<\/?formula>/gi, '').replace(/<\/?code>/gi, '');
}  // end removeFormulaTags

export const getSpreadsheetFunctionNames = (formulaText) => {
  const regex = /[A-Z]+\w*(?=\()/g
  const functionNames = formulaText.match(regex)
  const uniqueFunctionNames = [...new Set(functionNames)]
  const normalizedFunctionNames = uniqueFunctionNames.map(name => name.toUpperCase())
  return normalizedFunctionNames
} // end getSpreadsheetFunctionNames

export const removeExtraProperties = (arr) => {
  return arr.map(obj => {
    const { role, content } = obj;
    return { role, content };
  });
}



/**
 * Update conversations with a new message.
 *
 * @param {Object} newData - An object containing the role and content properties for the new message.
 * @param {string} newData.role - The role of the sender (e.g., 'admin', 'user').
 * @param {string} newData.content - The content of the new message.
 * @param {Object} conversations - An object containing the current conversations.
 * @param {string} conversationId - The ID of the conversation to which the new message will be added.
 * @returns {Object} The updated conversations object with the new message added to the specified conversation.
 */
export const updateConversations = (newMessage, conversations, conversationId) => {
  const updatedConversations = { ...conversations };
  const newMessages = [...updatedConversations[conversationId].messages, newMessage];
  updatedConversations[conversationId].messages = newMessages;
  return {updatedConversations,newMessages};
}

export const getRandomInt = (lower, upper) => {
  // Calculate the range of values we want to choose from
  const range = upper - lower + 1;
  // Generate a random decimal between 0 and 1, inclusive of 0 but exclusive of 1
  const randomDecimal = Math.random();
  // Scale the random decimal to the range of values we want to choose from
  const randomInRange = randomDecimal * range;
  // Convert the scaled random number to an integer and shift it to the appropriate starting point
  const randomInt = Math.floor(randomInRange) + lower;
  // Return the random integer
  return randomInt;
}


export const columnToLetter = (column) => {
  let columnLetter = '';
  while (column > 0) {
    const remainder = (column - 1) % 26;
    columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
    column = Math.floor((column - 1) / 26);
  }
  return columnLetter;
}

export const toA1 = (startRow, startCol, endRow, endCol) => {
  const startCell = columnToLetter(startCol) + startRow;

  if (endRow === undefined && endCol === undefined) {
    return startCell;
  }

  if (endRow !== undefined && endCol === undefined) {
    endCol = startCol;
  }

  if (endCol !== undefined && endRow === undefined) {
    endRow = startRow;
  }

  const endCell = columnToLetter(endCol) + endRow;
  return `${startCell}:${endCell}`;
}