// Test.js
import { useContext } from 'react';
import ConversationsContext from '../../../context/ConversationsContext';
import { useParams } from 'react-router-dom';

import { Alert, Grid, Box, Paper, Container } from "@mui/material";
import React, { useEffect } from "react";
import { AuthContext } from "../Auth";

import { SetPageTitle } from "../SetPageTitle";

import { UserMessageList } from "../user/UserMessageList";
import { UserQuestionFormBox } from '../user/UserQuestionFormBox';
import { UserFormulasBox } from '../user/UserFormulasBox';
import { BreadcrumbHeader } from './BreadcrumbHeader'



export const Conversations = () => {
    const { conversationId } = useParams();
    const { conversations } = useContext(ConversationsContext);

    const {
        messagesData,
        sendVerification,
        setFormulas,
        setFile
    } = useContext(ConversationsContext);

    useEffect(() => {
        localStorage.setItem("conversation", JSON.stringify(messagesData))
    }, [messagesData])

    useEffect(() => {
        setFormulas([])
        setFile(null)
    },[conversationId])


    if (!conversationId) return <div>No conversation selected</div>;

    // return storedConversationId ? { id: storedConversationId, messages: storedMessages } : null;

    return (
        <AuthContext.Consumer>
            {context => (
                <Container maxWidth="md">
                    <SetPageTitle title={`Conversation: ${conversations[conversationId].title}`} />
                    <Paper>
                        {sendVerification.error !== null &&
                            <Box p={2}>
                                <Alert severity="error">{sendVerification.error}</Alert>
                            </Box>
                        }
                        {sendVerification.success &&
                            <Box p={2}>
                                <Alert severity="success">Please check your email inbox to verify the email address. Refresh this page after you verified your email address.</Alert>
                            </Box>
                        }

                        <Grid container spacing={1}>
                            <Grid container item xs={12} md={12}>
                            <Grid item p={1} xs={12} md={12}>
                                <BreadcrumbHeader label={conversations[conversationId].title} />
                            </Grid>
                                <UserQuestionFormBox conversationId={conversationId} />
                                <UserFormulasBox />
                                <UserMessageList messagesData={conversations[conversationId].messages} />
                            </Grid>
                        </Grid>

                    </Paper>
                </Container>
            )}
        </AuthContext.Consumer>
    );
};