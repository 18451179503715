import { Alert, Grid, Box, Paper, Container, Button } from "@mui/material";
import React, { useContext} from "react";
import { AuthContext } from "../Auth";

import { SetPageTitle } from "../SetPageTitle";

import ConversationsList from "../main/ConversationsList";

import ConversationsContext from '../../../context/ConversationsContext';
import { useNavigate } from "react-router-dom";

export const UserPrivacy = () => {
    // const navigate = useNavigate();

    const {
        agreed,
        setAgreed,
        sendVerification,
    } = useContext(ConversationsContext);

    const navigate = useNavigate()

    const handleClick = () => {
        localStorage.setItem('privacyPolicyAgreed', true);
        setAgreed(true);
        setTimeout(() => {
            navigate("/")
        },1500)
    };

    // useEffect(() => {
    //     localStorage.setItem("conversation", JSON.stringify(messagesData))
    // }, [messagesData])

    return (
        <AuthContext.Consumer>
            {context => (
                <Container maxWidth="md">
                    <SetPageTitle title="Privacy Policy - Speadsheet AI" />
                    <Paper>
                        {sendVerification.error !== null &&
                            <Box p={2}>
                                <Alert severity="error">{sendVerification.error}</Alert>
                            </Box>
                        }
                        {sendVerification.success &&
                            <Box p={2}>
                                <Alert severity="success">Please check your email inbox to verify the email address. Refresh this page after you verified your email address.</Alert>
                            </Box>
                        }

                        <Grid container spacing={1}>
                            <Grid container item xs={12} md={12}>
                                <div style={{ padding: "2rem" }}>
                                    <Box>
                                        {!agreed && (
                                            <Button variant="contained" onClick={handleClick}>
                                                I Agree to the Privacy Policy
                                            </Button>
                                        )}
                                        {agreed && (
                                            <Button variant="contained" disabled>
                                                You Accepted & Agreed to Our Privacy Policy
                                            </Button>
                                        )}
                                    </Box>
                                    <h1>Privacy Policy</h1>
                                    <p>Last updated: April 3rd, 2023</p>
                                    <p>Chi Brander Inc. ("we", "us", or "our") is committed to protecting your privacy. This Privacy Policy outlines the types of information we collect, how we process it, and how we protect it. Please read this Privacy Policy carefully and make sure you understand it before using our services.</p>
                                    <p>By using our services, you agree to the collection and use of information in accordance with this policy. If you do not agree with any part of this Privacy Policy, please do not use our services.</p>
                                    <h2>1. No Personal or Private Information Should be Uploaded, Used, Entered by Users</h2>
                                    <p>We do not collect, store, or request any personal or private information from our users. However, our services may involve the processing of data by third-party providers, such as Google and OpenAI. When using our services, you acknowledge that these third-party providers may have their own privacy policies and practices, which we do not control. We encourage you to review the privacy policies of these third-party providers to understand how your information may be processed by them.</p>

                                    <h2>2. Data Storage and Security</h2>
                                    <p>All data generated through our services is stored locally on your device, and not on our servers. This means that if you move to a different device, your data will not be available on the new device. It is your responsibility to ensure the security of your device and the data stored on it.</p>

                                    <h2>3. Files Processing</h2>
                                    <p>When you use our services, any files you interact with are processed on your device and are not uploaded to our servers. This further protects your privacy by ensuring that we do not have access to the content of your files.</p>

                                    <h2>4. User Communication and Marketing Material</h2>
                                    <p>By providing your email address during registration or through other means, you agree to receive communication from us, which may include important updates, announcements, and marketing material related to our services. We value your privacy and will not share or sell your email address to any third parties. You can opt out of receiving marketing material at any time by following the unsubscribe instructions included in each email or by contacting us at mail@notification.chibrander.com.</p>
                                    <p>Please note that even if you opt out of receiving marketing material, we may still send you important updates and announcements related to our services.</p>

                                    <h2>5. Changes to This Privacy Policy</h2>
                                    <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes to this Privacy Policy will be posted on our website, and we encourage you to periodically review it for the latest information on our privacy practices. Your continued use of our services following the posting of changes to this Privacy Policy constitutes your acceptance of those changes.</p>

                                    <h2>6. Contact Us</h2>
                                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at mail@notification.chibrander.com.</p>

                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            )}
        </AuthContext.Consumer>
    )
}